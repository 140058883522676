import React from "react"
import { graphql, navigate } from "gatsby"
import styled from "styled-components/macro"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"
import SEO from "../components/seo"

import { Main, breakpoint as bp } from "../components/styles"
import Hero from "../components/hero"
import Layout from "../components/layout"
import ReviewsQuery from "../components/reviews/query"
import ReviewCard from "../components/reviews/card"
import ReviewDetails from "../components/reviews/modal"
import StarRating from "../components/starRating"

export default ({ data }) => {
  const { data: reviewData } = data.airtable

  const backButton = hide => (
    <Button className={`${hide === "md" ? "hide-md" : "hide-lg"} `}>
      <pw-button-primary-regular
        theme="dark"
        sidepadding="8"
        onClick={() => navigate("/")}
      >
        <pw-row
          style={{
            alignItems: "center",
          }}
        >
          <pw-col>
            <pw-i-filled-arrow-on-circle-left
              width={24}
              strokecolor="none"
              fillcolor="white"
              hover={false}
            />
          </pw-col>
          <pw-col>See all reviews</pw-col>
        </pw-row>
      </pw-button-primary-regular>
    </Button>
  )

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <React.Fragment>
          {modal ? (
            <pw-row>
              <ReviewDetails
                title={reviewData.title || reviewData.reviewer}
                comment={reviewData.comment}
                avatarUrl={
                  reviewData.avatar !== null && reviewData.avatar[0].url
                }
                rating={reviewData.rating}
                headerImage={
                  reviewData.backgroundUrl !== null &&
                  reviewData.backgroundUrl[0].url
                }
              />
            </pw-row>
          ) : (
            <Layout>
              <SEO title={reviewData.title || reviewData.reviewer} />
              <Hero />
              <header>
                <Main>
                  <Wrapper>
                    <pw-row style={{ justifyContent: "space-between" }}>
                      <pw-col nm="7" md="12">
                        <p-card
                          layout="column"
                          border
                          style={{
                            height: "auto",
                            minHeight: 500,
                            position: "relative",
                            overflow: "visible",
                          }}
                        >
                          <Avatar>
                            <pw-avatar
                              imageuri={reviewData.avatar[0].url}
                              size="130px"
                            ></pw-avatar>
                          </Avatar>
                          <p-card-content style={{ paddingTop: 65 }}>
                            <pw-heading size="small" color="green">
                              {reviewData.title || reviewData.reviewer}
                            </pw-heading>
                            {reviewData.rating && (
                              <StarRating isLeft rating={reviewData.rating} />
                            )}

                            <Paragraph>{reviewData.comment}</Paragraph>
                          </p-card-content>
                        </p-card>
                        {backButton("md")}
                      </pw-col>

                      <pw-col nm="4" md="12">
                        <ReviewsQuery limit={2}>
                          {({ reviews }) => (
                            <pw-row class="hide-sm">
                              {reviews.map(({ data }, index) => {
                                return (
                                  <pw-col nm="12" md="6" sm="12" key={index}>
                                    <ReviewCard
                                      title={data.title || data.reviewer}
                                      comment={data.comment}
                                      id={data.slug}
                                      link={data.link}
                                      avatarUrl={
                                        data.avatar !== null &&
                                        data.avatar[0].url
                                      }
                                      rating={data.rating}
                                      source={data.source}
                                      sourceLogo={
                                        data.sourceLogo !== null &&
                                        data.sourceLogo[0].url
                                      }
                                      headerImage={
                                        data.backgroundUrl !== null &&
                                        data.backgroundUrl[0].url
                                      }
                                    />
                                  </pw-col>
                                )
                              })}
                            </pw-row>
                          )}
                        </ReviewsQuery>
                        {backButton()}
                      </pw-col>
                    </pw-row>
                  </Wrapper>
                </Main>
              </header>
            </Layout>
          )}
        </React.Fragment>
      )}
    </ModalRoutingContext.Consumer>
  )
}

export const query = graphql`
  query GetRecord($slug: String!) {
    airtable(data: { slug: { eq: $slug } }) {
      data {
        reviewer
        date
        title
        comment
        link
        slug
        rating
        source
        sourceLogo {
          url
        }
        avatar {
          url
        }
        backgroundUrl {
          url
        }
      }
    }
  }
`

const Avatar = styled.div`
  transform: translateY(-50%);
  z-index: 34;
  position: absolute;
  margin: auto;
  left: 16px;
  /* margin-bottom: 16px; */
`

const Wrapper = styled.section`
  position: relative;
  background: var(--color-leaf-light);
  overflow: hidden;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
  padding: 10rem 0 14rem;

  @media only screen and (max-width: ${bp.small}) {
    .hide-sm {
      display: none;
    }
  }
`

const Paragraph = styled.p`
  font-size: 18px;
`

const Button = styled.div`
  max-width: 175px;
  margin-top: 5%;

  @media only screen and (min-width: ${bp.medium}) {
    &.hide-lg {
      display: none;
    }
  }

  @media only screen and (max-width: ${bp.medium}) {
    margin-left: auto;
    margin-right: auto;

    &.hide-md {
      display: none;
    }
  }

  pw-row {
    padding-right: 12px;
  }

  pw-col {
    padding: 4px !important;
  }
`
