import React from "react"
import styled from "styled-components/macro"
import StarRating from "../starRating"

function ReviewDetails({ title, comment, avatarUrl, rating }) {
  return (
    <pw-col nm="4" xxl="4" xl="5" lg="8" md="10" sm="12">
      <p-card layout="column" border>
        <Avatar>
          <pw-avatar imageuri={avatarUrl} size="130px"></pw-avatar>
        </Avatar>
        <p-card-content style={{ paddingTop: 65 }}>
          <AlignCenter>
            <pw-heading size="medium" style={{ color: "#3C4142" }}>
              {rating ? "Planswell Review" : title}
            </pw-heading>

            {rating && (
              <React.Fragment>
                <pw-body
                  size="small"
                  color="grey"
                  style={{ margin: "10px auto", textTransform: "uppercase" }}
                >
                  {title}
                </pw-body>
                <StarRating rating={rating} />
              </React.Fragment>
            )}
          </AlignCenter>
          <p-lead>
            <pw-overflow type="scroll-y">
              <p>{comment}</p>
            </pw-overflow>
          </p-lead>
        </p-card-content>
      </p-card>
    </pw-col>
  )
}

const Avatar = styled.div`
  transform: translate(-50%, -50%);
  z-index: 34;
  position: absolute;
  margin: auto;
  left: 50%;
  margin-bottom: 16px;
`

const AlignCenter = styled.div`
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledReviewDetails = styled(ReviewDetails)`
  .card-content {
  }
`

export default StyledReviewDetails
